<template>
    <div>
        <ViewTemplateWithSalveCancel :panel="panel">
            <div slot="content-main">
                <br>
                <Comunication :formName="panel.formName" />
            </div>
        </ViewTemplateWithSalveCancel>
    </div>
</template>
  
<script>


import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import Comunication from '../../../components/modules/adm/support/comunication/Comunication.vue'

export default {
    name: "ComunicationCreateUpdateView",
    components: {
        ViewTemplateWithSalveCancel, Comunication
    },
    data() {
        return {
            panel: {
                module: "Suporte",
                title: "Aviso",
                formName: "comunicationCreateUpdate",
                showFilter: false,
                showSearch: false,
                showButtons: false,
            },
        };
    },
};
</script>
  