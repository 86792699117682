<template>
    <div>
        <div>
            <Button _key="resetComunication" title="Reiniciar" classIcon="fa-sharp fa-solid fa-power-off" type="primary"
                size="small" :clicked="confirm" />
            <br>
        </div>
        <br>
        <Molded>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <InputText title="Titulo" field="name" :formName="formName" :required="true" :maxLength="100"
                        v-model="comunication.title">
                    </InputText>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div class="text-center">
                        <ButtonFilter :options="[
                            {
                                title: 'Visualizar',
                                classIcon: 'fa-solid fa-eye',
                                value: 'viewText',
                            },
                            {
                                title: 'Html',
                                classIcon: 'fa-solid fa-code',
                                value: 'codeEditor',
                            },
                        ]" initialOption="viewText" v-model="selectType" />
                    </div>
                    <Molded>
                        <div class="div-view" v-if="selectType == 'viewText'" v-html="comunication.htmlContent"></div>
                    </Molded>
                    <TextArea v-if="selectType == 'codeEditor'" v-model="comunication.htmlContent" />
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <br>
                    <DateTime title="Fim" :formName="formName" :required="true" fieldTarget="dateFinalize"
                        format="DD/MM/YYYY" type="date" :range="false" v-model="comunication.dateFinalize" />
                </b-col>
            </b-row>
        </Molded>
        <br />
        <Modal title="Tem certeza que deseja reiniciar?" :width="500" v-show="showModal('restart')">
            <Alert type="warning">
                <span>
                    Atenção, todos os usuários receberão o aviso ao logar no sistema!
                </span>
            </Alert>
            <Confirmation :isModal="false" title="Você tem certeza?" type="primary" :confirmed="restart" />
        </Modal>
    </div>
</template>
  
<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import ButtonFilter from "@nixweb/nixloc-ui/src/component/forms/ButtonFilter";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";


import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
    name: "UserCreateUpdate",
    props: {
        formName: String,
    },
    components: {
        Molded,
        InputText,
        EditorHtml,
        TextArea,
        ButtonFilter,
        DateTime,
        Button,
        Confirmation,
        Modal,
        Alert
    },
    data() {
        return {
            selectType: "viewText",
            comunication: {
                id: "",
                title: "",
                htmlContent: "",
                dateFinalize: "",
            },
            urlCreate: "/api/v1/support/comunication/create",
            urlUpdate: "/api/v1/support/comunication/update",
            urlGet: "/api/v1/support/comunication/get",
            urlUpdateComunication: "/api/v1/support/user/update-comunication",
        };
    },
    created() {
        this.get();
    },
    methods: {
        ...mapMutations("validation", ["removeFormDirty"]),
        ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
        ...mapActions("generic", ["postApi", "putApi", "getApi"]),
        create() {
            let params = { url: this.urlCreate, obj: this.comunication };
            this.postApi(params).then((response) => {
                this.removeLoading(["saveSaveCancel"]);
            });
        },
        update() {
            let params = { url: this.urlUpdate, obj: this.comunication };
            this.putApi(params).then((response) => {
                this.removeLoading(["saveSaveCancel"]);
            });
        },
        get() {
            let params = { url: this.urlGet, obj: {} };
            this.getApi(params).then((response) => {
                if (response.success) {
                    if (response.content == null) {
                        this.comunication.id = "";
                    } else {
                        this.comunication = {
                            id: response.content.id,
                            title: response.content.title,
                            htmlContent: response.content.htmlContent,
                            dateFinalize: response.content.dateFinalize,
                        };
                    };
                    let self = this;
                    setTimeout(function () {
                        self.removeLoading(["panel"]);
                        self.removeFormDirty();
                    }, 100);
                }
            });
        },
        confirm() {
            this.openModal("restart");
            this.removeLoading(["resetComunication"]);
        },
        restart() {
            let params = { url: this.urlUpdateComunication, notNotifyToast: true };
            this.putApi(params).then((response) => {
                this.hideModal();
                this.removeLoading(["confirm"]);
            });
        }
    },
    computed: {
        ...mapGetters("generic", ["event", "showModal"]),
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "saveSaveCancel") {
                    if (!this.comunication.id) this.create();
                    if (this.comunication.id) this.update();
                }
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.div-view {
    margin: auto;
    width: 800px;
}
</style>