var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('Button',{attrs:{"_key":"resetComunication","title":"Reiniciar","classIcon":"fa-sharp fa-solid fa-power-off","type":"primary","size":"small","clicked":_vm.confirm}}),_c('br')],1),_c('br'),_c('Molded',[_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('InputText',{attrs:{"title":"Titulo","field":"name","formName":_vm.formName,"required":true,"maxLength":100},model:{value:(_vm.comunication.title),callback:function ($$v) {_vm.$set(_vm.comunication, "title", $$v)},expression:"comunication.title"}})],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('div',{staticClass:"text-center"},[_c('ButtonFilter',{attrs:{"options":[
                        {
                            title: 'Visualizar',
                            classIcon: 'fa-solid fa-eye',
                            value: 'viewText',
                        },
                        {
                            title: 'Html',
                            classIcon: 'fa-solid fa-code',
                            value: 'codeEditor',
                        } ],"initialOption":"viewText"},model:{value:(_vm.selectType),callback:function ($$v) {_vm.selectType=$$v},expression:"selectType"}})],1),_c('Molded',[(_vm.selectType == 'viewText')?_c('div',{staticClass:"div-view",domProps:{"innerHTML":_vm._s(_vm.comunication.htmlContent)}}):_vm._e()]),(_vm.selectType == 'codeEditor')?_c('TextArea',{model:{value:(_vm.comunication.htmlContent),callback:function ($$v) {_vm.$set(_vm.comunication, "htmlContent", $$v)},expression:"comunication.htmlContent"}}):_vm._e()],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"2","xl":"2"}},[_c('br'),_c('DateTime',{attrs:{"title":"Fim","formName":_vm.formName,"required":true,"fieldTarget":"dateFinalize","format":"DD/MM/YYYY","type":"date","range":false},model:{value:(_vm.comunication.dateFinalize),callback:function ($$v) {_vm.$set(_vm.comunication, "dateFinalize", $$v)},expression:"comunication.dateFinalize"}})],1)],1)],1),_c('br'),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal('restart')),expression:"showModal('restart')"}],attrs:{"title":"Tem certeza que deseja reiniciar?","width":500}},[_c('Alert',{attrs:{"type":"warning"}},[_c('span',[_vm._v(" Atenção, todos os usuários receberão o aviso ao logar no sistema! ")])]),_c('Confirmation',{attrs:{"isModal":false,"title":"Você tem certeza?","type":"primary","confirmed":_vm.restart}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }